<template>
  <div class="full-panel">
    <h1 class="text-center">獲勝者</h1>
    <h2 class="text-center">{{ winner }}</h2>
    <div class="position-relative winner-container">
      <div class="position-absolute winner-data">
        <figure class="cover-fit winner-avatar circle cha-circle">
          <img class="cover-fit" :src="showAvatar(winnerData.avatar)" alt="" />
        </figure>
        <div class="text-center">{{ winnerData.nickname }}</div>
      </div>
      <div
        class="position-relative winner-contain overflow-hidden"
        :style="{ 'background-img': winnerCharacter.figure }"
      >
        <img class="img-fluid" :src="showAvatar(winnerCharacter.figure)" alt="" />
      </div>
    </div>
    <button class="btn btn-primary btn-block" @click="next">觀看結局</button>
  </div>
</template>

<script>
import CharacterFromVuex from "../Mixins/CharacterFromVuex";
import Avatar from "../Mixins/Avatar";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import UserFromVuex from "../Mixins/UserFromVuex";

export default {
  name: "winner",
  mixins: [Avatar, TeamFromVuex, UserFromVuex, CharacterFromVuex],
  data() {
    return {
      winner: "白爛",
      winnerCharacter: {},
      winnerData: {
        nickname: "www",
        picture: ""
      }
    };
  },

  mounted() {
    let scores = Object.keys(this.score_map).map(key => {
      return {
        user_id: key,
        score: this.score_map[key]
      };
    });
    let winner_id = scores.sort((a, b) => b.score - a.score)?.[0]?.user_id;
    let winner_character = this.character_map.find(d => d.user_id == winner_id)?.character;
    this.winner = winner_character.name;
    this.winnerCharacter = winner_character;
    this.winnerData = this.members.find(d => d.user_id == winner_id)?.user;
  },
  methods: {
    next() {
      this.$router.replace("winnerVideo");
    }
  }
};
</script>

<style scoped lang="scss">
h1 {
  padding: 30px 0 0px;
  font-size: 30px;
}

h2 {
  font-size: 25px;
}

.winner-contain {
  height: 60vh;
  &::after {
    content: "";
    height: 80px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
  }
}
.winner-data {
  right: 20px;
  bottom: 0px;
  z-index: 10;
  font-size: 18px;
}
.winner-avatar {
  width: 100px;
  height: 100px;
}
</style>

<template>
  <div
    class="full-page position-relative"
    :class="{ 'stage-content': !flag('stage_5_check1stPlace') || flag('stage_5_fgClear') }"
    ref="finalContent"
  >
    <!-- <div class="bg-white position-fixed"> -->
    <!-- <p>stage_5_compass: {{ flag('stage_5_compass') }}</p>
      <p>stage_5_compass_screen_center: {{ flag('stage_5_compass_screen_center') }}</p>
      <p>stage_5_compass_lock: {{ flag('stage_5_compass_lock') }}</p>
      <p>stage_5_compass_touch: {{ flag('stage_5_compass_touch') }}</p>
      <p>stage_5_compass_show_hint: {{ flag('stage_5_compass_show_hint') }}</p>
      <p>compassHide: {{ compassHide }}</p> -->
    <!-- {{hint_messages}} -->
    <!-- </div> -->
    <!--    {{ flag('stage_5_check1stPlace') }}-->
    <!--    {{ flag('stage_5_fgClear') }}-->
    <!-- 1.初始畫面，到達地點 -->
    <div v-if="isStep(1)">
      <stageHeader :character="stageCharacter" :stageLocation="stageLocation"></stageHeader>
      <checkPosition :stage_id="5" :location="check1stPosition" :local="[25.03887, 121.513]"></checkPosition>
      <mainMenu></mainMenu>
    </div>

    <!-- 2.撥放影片與尋找地點 -->
    <div v-if="isStep(2)">
      <div v-if="checkShowWinner" class="gather">
        <logoutBtn position="lower-right"></logoutBtn>
        <h4 class="position-fixed nickname">{{ winner_nickname }}</h4>
        <div class="position-fixed bg">
          <img src="img/fg-gather-bg.png" alt="找到地點背景" />
        </div>
        <div class="position-fixed continue-btn" @click="goNextPhase">
          <img src="img/fg-continue-btn.png" alt="繼續遊戲" />
        </div>
        <div class="position-fixed compass compass-active">
          <img src="img/compass1.png" alt="找到地點" />
        </div>
      </div>
      <div v-if="!checkShowWinner">
        <div v-if="!compassHide" class="position-fixed">
          <div
            v-if="flag('stage_5_compass_screen_center')"
            class="hint d-flex flex-row justify-content-center align-content-center"
          >
            <div class="hint-content">{{ message }}</div>
            <a role="button" class="stage-hint" @click="showTip">
              <svg class="btn-icon-28">
                <use xlink:href="#icon-tip" class="fill-white active"></use>
              </svg>
            </a>
          </div>
          <div class="full-blood position-fixed text-center">
            <img v-if="flag('stage_5_blood') == 0" class="cover-fit" src="/img/blood1.png" alt="" />
            <img v-if="flag('stage_5_blood') == 1" class="cover-fit" src="/img/blood2.png" alt="" />
            <img v-if="flag('stage_5_blood') == 2" class="cover-fit" src="/img/blood3.png" alt="" />
          </div>
        </div>

        <div
          class="compass position-fixed"
          :class="{
            active: flag('stage_5_compass_screen_center'),
            'compass-hide': compassHide,
            'compass-lock': flag('stage_5_compass_lock')
          }"
          @click.stop.prevent="showCompass"
        >
          <!-- <transition name="fade">
            <div class="compass-arrow position-fixed" v-show="flag('stage_5_compass_show_hint')"></div>
          </transition> -->
          <div
            class="compass-hint position-fixed"
            :class="{ 'hint-hide': !flag('stage_5_compass_show_hint') }"
            v-if="!flag('stage_5_compass_show_hint')"
          >
            開啟羅盤偵測地點
          </div>
          <div class="position-relative compass-img">
            <div
              v-if="flag('stage_5_compass_screen_center')"
              class="compass-detect-area position-absolute"
              @click.stop.prevent="compassClickedThenCheck"
            ></div>

            <img v-if="nearBy == 0" src="img/compass1.png" alt="閒置" />
            <img v-if="nearBy == 2" src="img/compass2.png" alt="錯誤" />

            <img v-if="nearBy == 1" src="img/compass3.png" alt="正確" />
          </div>
          <!--<div v-if="nearBy != 0 && showClickResult" class="position-fixed text-center detect-text">-->
          <!--<div class="position-fixed text-center detect-text">-->
          <div v-if="showClickResult" class="position-fixed text-center detect-text">
            <img class="result" v-if="nearBy == 1" src="img/fg-place-found.png" alt="找到地點" />
            <img class="result" v-else-if="nearBy == 2" src="img/fg-place-not-found.png" alt="錯誤地點" />
            <div class="result searchingText" v-else>尋找中...</div>
          </div>
        </div>

        <div class="full-page d-flex align-items-center">
          <vimeo
            v-if="phaseIndex == 0 && flag('stage_5_check1stPlace')"
            :vimeo_key="'S03'"
            @timeupdate="videoTimeUpdateEvent"
            @ended="viedoEnd(0)"
          ></vimeo>
          <vimeo v-if="phaseIndex == 1" :vimeo_key="'S03A'" @ended="viedoEnd(1)"></vimeo>
          <vimeo v-if="phaseIndex == 2" :vimeo_key="'S03B'" @ended="viedoEnd(2)"></vimeo>
          <vimeo v-if="phaseIndex == 3" :vimeo_key="'S03C'" @ended="lastEnd"></vimeo>
        </div>
      </div>
    </div>

    <!-- 3.瀏覽之前劇情，拍照結束 -->
    <template v-if="isStep(3)">
      <stageHeader :character="stageCharacter" :stageLocation="stageLocation"></stageHeader>
      <moreContent @click="moreContent = false" v-if="moreContent"></moreContent>
      <moreContent @click="moreContent = false" v-if="moreContent"></moreContent>
      <FavorContainer
        v-if="flag('stage_5_clear') && !flag('stage_5_clear_favor')"
        @close="phase5bonusDone"
        :stage="stage"
        :step="1"
        :maximum_step="1"
        :stageFinished="true"
      ></FavorContainer>
      <igStory :video="fgIgStory1" :overBackOffscreen="false"></igStory>
      <fgStageMessage
        :user="user"
        :data="phase1Messages"
        :stage="stage"
        :step="1"
        :charName="getCharacterName(0)"
        v-on:stageClear="stageClear"
      ></fgStageMessage>
      <igStory :video="fgIgStory2" :overBackOffscreen="false"></igStory>
      <fgStageMessage
        :user="user"
        :data="phase2Messages"
        :stage="stage"
        :step="2"
        :charName="getCharacterName(1)"
        v-on:stageClear="stageClear"
      ></fgStageMessage>
      <igStory :video="fgIgStory3" :overBackOffscreen="false"></igStory>
      <fgStageMessage
        :user="user"
        :data="phase3Messages"
        :stage="stage"
        :step="3"
        :charName="getCharacterName(2)"
        v-on:stageClear="stageClear"
      ></fgStageMessage>
      <igStory :video="fgIgStory4" :overBackOffscreen="false"></igStory>
      <fgStageMessage
        :user="user"
        :data="phase4Messages"
        :stage="stage"
        :step="4"
        :charName="getCharacterName(2)"
        v-on:stageClear="stageClear"
      ></fgStageMessage>
      <photoMission :photo="photoMission" :stage="stage" @final="final" @next="final">
        <fgStageMessage
          :user="user"
          :data="phase5Messages"
          :stage="stage"
          :step="4"
          :charName="0"
          v-on:stageClear="stageClear"
          @showStageFinishedFavor="showStageFinishedFavor"
        ></fgStageMessage>
      </photoMission>
      <div class="empty-div"></div>
      <mainMenu></mainMenu>
    </template>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import mainMenu from "@/views/part/mainMenu";
import FavorContainer from "./favor/FavorContainer";

import stageHeader from "@/views/part/stageHeader";
import photoMission from "@/views/part/photoMission";
import moreContent from "@/views/part/moreContent";
import checkPosition from "@/views/part/checkPosition";

import { mapGetters } from "vuex";

import { getDistance } from "@/Mixins/getDistance";
import fgStageMessage from "@/views/part/fgStageMessage";
import vimeo from "./part/vimeo";
import logoutBtn from "@/views/part/logoutBtn";
import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import ModalMixin from "@/Mixins/ModalMixin";
import UserFromVuex from "../Mixins/UserFromVuex";
import StageFromMixin from "../Mixins/StageFromMixin";
import igStory from "@/views/part/igStory";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import DeviceApiMixin from "../Mixins/DeviceApiMixin";
import FavorFromMixin from "@/Mixins/FavorFromMixin";
import winner from "@/views/winner";
import HowlAudio from "@/Mixins/HowlAudio";
import { getCurrentPositionPromise } from "@/libs/getCurrentPosition";

export default {
  name: "fbCompass",
  mixins: [
    Avatar,
    CharacterFromVuex,
    WebSocketMixin,
    UserFromVuex,
    StageFromMixin,
    ModalMixin,
    TeamFromVuex,
    DeviceApiMixin,
    FavorFromMixin,
    HowlAudio
  ],
  components: {
    mainMenu,
    fgStageMessage,
    checkPosition,
    stageHeader,
    photoMission,
    vimeo,
    igStory,
    moreContent,
    FavorContainer,
    logoutBtn
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    ...mapGetters("Video", ["getVideoPath", "getVimeoId"]),
    step_map() {
      return {
        1: !this.flag("stage_5_check1stPlace") && !this.flag("stage_5_fgClear"),
        2: !this.flag("stage_5_fgClear"),
        3: this.flag("stage_5_check1stPlace") && this.flag("stage_5_fgClear")
      };
    },
    stageCharacter() {
      return this.getRoleByName(this.siteCharacter.name);
    },
    // message() {
    //   switch (this.nearBy) {
    //     case 0:
    //       return "";
    //     case 1:
    //       return "是靈動反應!<br>一定是這裡!";
    //     case 2:
    //       return "指針沒什麼反應<br>看來不是這裡";
    //   }
    //   return false;
    // },
    element_id() {
      return "vimeo-" + this.positionPhase;
    },
    compassHide() {
      return (
        !this.flag("stage_5_compass_screen_center") &&
        !this.flag("stage_5_compass_lock") &&
        !this.flag("stage_5_compass_touch")
      );
    },
    hintIndexFlag() {
      return `stage_5_video_${this.flag("stage_5_compass")}_hintIndex`;
    },
    phaseIndex() {
      return this.flag("stage_5_compass");
    },
    currentPhaseMessages() {
      // let hintIndex = this.flag(this.hintIndexFlag);
      let phaseMessages = {};
      if (this.phaseIndex == 0) {
        phaseMessages = this.phase1Messages;
      } else if (this.phaseIndex == 1) {
        phaseMessages = this.phase2Messages;
      } else {
        phaseMessages = this.phase3Messages;
      }
      return phaseMessages;
    },
    currentHints() {
      return this.currentPhaseMessages?.hint;
    },
    hint_messages() {
      let result = {};
      let stage_id = this.stage?.stage_id.toString();

      if (this?.team_payload?.stageMessage?.[stage_id]) {
        let list = this.team_payload.stageMessage[stage_id];
        Object.keys(list).forEach(step => {
          result[step] = list[step].filter(d => {
            if (d.is_hint && d.only) return d.only == this.user.id;
          });
        });
      }

      return result;
    },
    message() {
      let lastIndex = this.hint_messages?.[this.phaseIndex]?.length || -1;
      if (lastIndex >= 0) {
        lastIndex = lastIndex > 0 ? lastIndex - 1 : 0;
        return this.hint_messages?.[this.phaseIndex][lastIndex].content;
      } else return "";
    },
    winner_nickname() {
      let winner_user_id = this.flag(`stage_5_place_${this.phaseIndex}_winner_user`);
      let winner_user = this.members.find(member => {
        return member?.user_id == winner_user_id;
      });
      if (winner_user) {
        return winner_user?.user?.nickname || "";
      }
      return "";
    },
    checkShowWinner: function() {
      if (!this.flag("stage_5_continue_btn")) {
        return false;
      }
      let winner_user_id = this.flag(`stage_5_place_${this.phaseIndex}_winner_user`);
      console.log("winner_user: ", winner_user_id, "| user_id: ", this.user?.id);
      if (!this.flag(`stage_5_place_${this.phaseIndex}_winner_user`)) {
        return false;
      }
      // if(winner_user_id === this.user?.id){
      //   return false;
      // }
      console.log("check show winner!!!!!");
      return true;
    }
  },
  data() {
    return {
      moreContent: false,
      cry_audio: [],
      width: 300,
      stage: {
        stage_id: 5
      },
      siteCharacter: {
        name: "白爛"
      },
      stageLocation: {
        time: "高二上",
        location: "北一女"
      },
      check1stPosition: {
        nextPhase: "stage_5_check1stPlace",
        position: [25.03887, 121.513],
        distance: 70
      },
      timer: null,
      ghostPlayTime: 30,
      ghostSound: ["ghost1.mp3", "ghost2.mp3", "ghost3.mp3"],
      compassClicked: 0,
      showClickResult: false,
      nearBy: 0, //0: default, 1: near, 2: far

      // videos: ["fg-p1.mp4", "fg-p2.mp4", "fg-p3.mp4"],
      fgIgStory1: {
        src: "S03",
        poster: "mc/5.jpg",
        nextPhase: ""
      },
      fgIgStory2: {
        src: "S03A",
        poster: "mc/6.jpg",
        nextPhase: ""
      },
      fgIgStory3: {
        src: "S03B",
        poster: "mc/7.jpg",
        nextPhase: ""
      },
      fgIgStory4: {
        src: "S03C",
        poster: "mc/8.jpg",
        nextPhase: ""
      },
      phase1Messages: {
        msgLikes: "",
        character: "白爛",
        defaultMsg: [
          {
            name: "白爛",
            content: "只要用我大陰陽世家的靈動羅盤，肯定可以找到靈異地點"
          },
          {
            name: "小希",
            content: "你不要亂講啦⋯⋯我會怕⋯"
          },
          {
            name: "小桃",
            content: "這⋯⋯這是你編的吧⋯⋯我從來沒有聽學姊說過⋯⋯"
          },
          {
            name: "花輪",
            content: "北鼻們不要怕～有我在！"
          }
        ],
        winnerMsg: {
          winner: 0, //0: 其他, 1: 白爛
          message: ["咦～[charName]你很有潛力，來當我徒弟吧！", "我大陰陽世家就是行！"]
        },
        hintIndex: 0,
        hint: [
          "（1/3）面對校門口往左側看，周圍有什麼綠色的東西嗎？",
          "（2/3）從門口算十三棵樹會有什麼呢？",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "面對門口，向左手邊算十三棵樹有一道綠門"
        ]
      },
      phase2Messages: {
        msgLikes: "",
        character: "白爛",
        defaultMsg: [
          {
            name: "白爛",
            content: "好⋯學姊妳坐我肩膀上⋯我幫妳找頭"
          },
          {
            name: "小希",
            content: "死白爛！你不要在那邊裝啦⋯很可怕耶⋯"
          },
          {
            name: "胖子",
            content: "最好啦！這不科學啊！"
          }
        ],
        winnerMsg: {
          winner: 0, //0: 其他, 1: 白爛
          message: ["啊啊啊！[charName]你也踢到學姊的頭啦！", "啊啊啊！我剛剛是不是踢到什麼東西了"]
        },
        hintIndex: 0,
        hint: [
          "（1/3）過馬路之後有沒有發現花呢？",
          "（2/3）附近有沒有跟『亭』有關的事物呢？",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "在介壽公園中找到醒獅亭"
        ]
      },
      phase3Messages: {
        msgLikes: "",
        character: "白爛",
        defaultMsg: [
          {
            name: "白爛",
            content: "學姊說不幫她就不放過我們 😲"
          },
          {
            name: "赤木",
            content: "白爛你在玩我們吧！"
          },
          {
            name: "小桃",
            content: "嗚⋯不要鬧了啦！"
          }
        ],
        winnerMsg: {
          winner: 0, //0: 其他, 1: 白爛
          message: ["找到啦！[charName]你可真救了我的下半生(身)啦！", "找到啦！我媽差點就多個媳婦了！"]
        },
        hintIndex: 0,
        hint: [
          "（1/3）四周觀望一下有看到紅人嗎？",
          "（2/3）林老好像是某個人物的代稱",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "在介壽公園中找到林森先生銅像"
        ]
      },
      phase4Messages: {
        msgLikes: "",
        character: "白爛",
        defaultMsg: [
          {
            name: "白爛",
            content: "YES！YES！找到了！來我們來自拍一張啊"
          },
          {
            name: "小桃",
            content: "who怕who！"
          }
        ]
      },
      phase5Messages: {
        msgLikes: "",
        character: "白爛",
        defaultMsg: [
          {
            name: "白爛",
            content: "鬼故事極短篇－其實啊，我原本是能上北一女的，但我後來發現我更想上成功"
          },
          {
            name: "小桃",
            content: "最好啦！真的很白爛耶🙄"
          },
          {
            name: "白爛",
            content: "啊不然我怎麼叫白爛勒🤪"
          },
          {
            name: "提示",
            is_hint: true,
            content:
              "你們可以參考範例照片的地點和姿勢、重現當年的回憶；或是自行選擇你們喜愛的地點、姿勢或方式，拍照並上傳照片，<strong>照片請用正方形格式拍攝，超出將會被剪裁。</strong>"
          }
        ]
      },
      photoMission: {
        img: "photo-fg.jpg",
        compositeImg: "fg-ghost.png",
        finish: "stage_5_photo_finished",
        flag: "stage_5_clear",
        local: [25.039072, 121.514989],
        flyTo: [25.039033, 121.51472]
      },

      positionPhase: 0,
      position: [
        {
          location: [25.038649, 121.51382],
          distance: 25,
          clear: false
        },
        {
          location: [25.039033, 121.514397],
          distance: 25,
          clear: false
        },
        {
          location: [25.039072, 121.514989],
          distance: 25,
          clear: false
        }
      ]
    };
  },
  mounted() {
    this.resetCompass();
    this.width = window.innerWidth;
    this.positionPhase = this.flag("stage_5_compass");
    this.timer = setInterval(this.ghostCry, this.ghostPlayTime * 1000);
    for (let i = 0; i < 3; i++) {
      this.cry_audio[i] = this.makeAudio("audio/" + this.ghostSound[i]);
    }

    if (this.positionPhase == 0) {
      this.$nextTick(() => {
        // window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        // this.$refs.video.addEventListener("timeupdate", () => {
        //   let videoTime = this.$refs?.video?.currentTime;
        //   if (videoTime >= 143) this.compassShow = true;
        // });
        // this.$refs.video.addEventListener("play", () => {
        //   // this.videoPaused = false;
        // });
        // this.$refs.video.addEventListener("pause", () => {});
        // this.$refs.video.addEventListener("ended", () => {
        //   this.compassArrowShow = true;
        //   this.story1 = true;
        //   this.compassTouch = true;
        //   this.positionPhase = 1;
        // });
      });
    }
  },
  watch: {
    // positionPhase: function(value) {
    //   if (value == 4) {
    //     this.$nextTick(() => this.scrollToEnd());
    //     // window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    //   }
    // }
  },
  methods: {
    isStep(step) {
      return this.step_map?.[step];
    },
    resetCompass() {
      this.setFlag({ key: "stage_5_continue_btn", status: false, user: this.user });
      this.setFlag({ key: "stage_5_compass_show_hint", status: false, user: this.user });
      this.setFlag({ key: "stage_5_compass_lock", status: false, user: this.user });
      this.setFlag({ key: "stage_5_compass_screen_center", status: false, user: this.user });
      this.setFlag({ key: "stage_5_compass_touch", status: false, user: this.user });
    },
    showCompass() {
      console.log("~~~~~~~~~~ show Compass!!!!");
      this.setFlag({ key: "stage_5_compass_show_hint", status: false, user: this.user });
      this.setFlag({ key: "stage_5_compass_lock", status: false, user: this.user });
      this.setFlag({ key: "stage_5_compass_screen_center", status: true, user: this.user });
      this.setFlag({ key: "stage_5_compass_touch", status: true, user: this.user });
    },
    videoTimeUpdateEvent(event) {
      if (event.seconds >= 136.25 && event.seconds <= 137) {
        this.setFlag({ key: "stage_5_compass_touch", status: false, user: this.user });
        this.setFlag({ key: "stage_5_compass_screen_center", status: false, user: this.user });
        this.setFlag({ key: "stage_5_compass_touch", status: false, user: this.user });
        this.setFlag({ key: "stage_5_compass_show_hint", status: false, user: this.user });
        this.setFlag({ key: "stage_5_compass_lock", status: true, user: this.user });
        this.setFlag({ key: "stage_5_blood", status: null, user: this.user });
      }
    },
    viedoEnd(phase) {
      this.setFlag({ key: `stage_5_video_${phase}_ended`, status: true, user: this.user });
      this.setFlag({ key: "stage_5_compass_show_hint", status: true, user: this.user });
      this.setFlag({ key: "stage_5_compass_touch", status: true, user: this.user });
      this.setFlag({ key: "stage_5_compass_screen_center", status: false, user: this.user });
      this.setFlag({ key: "stage_5_compass_lock", status: false, user: this.user });
      this.setFlag({ key: "stage_5_blood", status: phase, user: this.user });
      this.setFlag({ key: "stage_5_compass", status: phase, user: this.user });
    },
    lastEnd() {
      console.log("in lastEnd~~~~~~~~~~");
      // this.setFlag({key: 'stage_5_blood', status: 3});
      this.setFlag({ key: "stage_5_fgClear", status: true });
    },
    final() {
      this.unlockStage(5);
      this.setFlag({ key: `stage_5_photo_finished`, status: true });
    },
    next() {
      console.log("$emit next");
      // this.setFlag({ key: `stage_5_photo_finished`, status: true });
    },
    ghostCry() {
      if (!this.flag("stage_5_fgClear") && !this.compassHide) {
        let randomIndex = Math.floor(Math.random() * 3);
        this.cry_audio[randomIndex].play();
      }
    },
    scrollToEnd: function() {
      // var content = this.$refs.finalContent;
      // content.scrollTop = content.scrollHeight
    },
    getCharacterName(stage) {
      let user_id = this.flag("stage_5_place_" + stage + "_winner_user");
      let winner_character = this.character_map.find(thisCharacter => {
        return thisCharacter?.user_id == user_id;
      });
      return winner_character?.character?.name || "";
    },
    showTip: function() {
      if (this.currentHints) {
        let hintIndex = this.hint_messages?.[this.phaseIndex]?.length
          ? this.hint_messages?.[this.phaseIndex]?.length
          : 0;

        if (hintIndex < this.currentHints.length) {
          let content = this.currentHints[hintIndex]?.content || this.currentHints[hintIndex];

          let newMsg = {
            character: null,
            content: content,
            result: "",
            is_hint: true
          };
          this.setUserHint({
            stage: this.stage,
            step: this.phaseIndex,
            hintIndex,
            newMsg
          });
        }
      }
    },
    stageClear: function() {
      this.final();
    },
    async compassClickedThenCheck() {
      this.showClickResult = true;
      this.nearBy = 0;
      let phaseIndex = this.flag("stage_5_compass");
      var audio = this.makeAudio("/audio/compass.mp3"); // path to file
      audio.play();
      console.log("check compassClicked_________", this.compassClicked);
      if (this.compassClicked > 0) {
        return;
      }

      let result = await this.isNearPosition(this.position[phaseIndex]);

      if (result == 1) {
        this.compassClicked++;
        console.log("check winner: ", this.user);
        this.setWinner({
          winner: this.character,
          user: this.user,
          stage: this.stage,
          step: phaseIndex
        });
        this.addScoreByRate({
          score: 10,
          characterName: this.character?.name,
          isMoveStage: false,
          hint_key: `stage_5_video_${phaseIndex}_hintIndex`
        });

        this.setFlag({ key: `stage_5_place_${phaseIndex}_winner_user`, status: this.user?.id });

        this.nearBy = 1;

        setTimeout(() => {
          this.resetToDefaultState();
          this.showContinueBtn();
        }, 3000);
      } else if (result == -1) {
        this.nearBy = 2;
        this.compassClicked = 0;
        setTimeout(() => {
          setTimeout(this.resetToDefaultState, 3000);
        }, 3000);
      } else {
        //無法取得定位
        setTimeout(this.resetToDefaultState, 1000);
      }
    },
    resetToDefaultState() {
      this.nearBy = 0;
      this.showClickResult = false;
      this.compassClicked = 0;
    },
    async isNearPosition(position) {
      if (navigator.geolocation) {
        try {
          let promise = await getCurrentPositionPromise({
            timeout: 20000,
            maximumAge: 1000,
            enableHighAccuracy: true
          })
            .then(p => {
              let distance = getDistance(
                p.coords.latitude,
                p.coords.longitude,
                position.location[0],
                position.location[1],
                "M"
              );
              console.log("isNearPosition", distance, p.coords);
              return distance <= position.distance ? 1 : -1;
            })
            .catch(e => {
              console.log(e);
              this.showModal("無法取得定位");
              return -2;
            });

          return await promise;
        } catch (e) {
          this.showModal("無法取得定位");
          return false;
        }
      } else {
        this.showModal({ content: "您的瀏覽器不支援地理定位" });
        return false;
      }
    },
    showContinueBtn() {
      console.log("check show continue btn!!!!!!!!!!!!!");
      this.setFlag({ key: "stage_5_continue_btn", status: true });
    },
    async goNextPhase() {
      // let phaseIndex = this.flag("stage_5_compass");
      // let result = await this.isNearPosition(this.position[phaseIndex]);
      // if (result == 1) {
      this.nearBy = 0;
      this.compassClicked = 0;
      this.resetCompass();
      let phaseIndex = this.flag("stage_5_compass");
      phaseIndex++;
      this.setFlag({ key: "stage_5_compass", status: phaseIndex, user: this.user });
      // } else if (result == -1) {
      //   this.showModal("未抵達任務地點");
      // }
    },
    phase5bonusDone: function() {
      this.setFlag({ key: "stage_5_clear_favor", status: true });
      this.$router.replace("homeMap");
    },
    showStageFinishedFavor() {
      this.setFlag({ key: "stage_5_finished", status: true });
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = false;
  }
};
</script>

<style scoped lang="scss">
/* 鎖定雙擊選取功能 */
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

@keyframes arrowUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.full-blood {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.detect-text {
  left: -10vw;
  top: -45%;
  right: -10vw;
  bottom: 0;
  z-index: 99;

  .result {
    object-fit: contain;
    width: 100vw;
  }
}

.hint-message {
  position: fixed;
  top: 80px;
  left: 30px;
  right: 30px;
  padding: 10px 20px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: none;
  transition: 0.3s;
  /*pointer-events: none;*/
  &.active {
    /*opacity: 1;*/
    display: block;
    /*pointer-events: auto;*/
  }
}

.icon-tip {
  right: 30px;
  top: 54px;
  z-index: 10;
}

.gather {
  .nickname {
    top: 15vh;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    width: 100vw;
    text-align: center;
    height: 30px;
    color: #fff;
  }

  .continue-btn {
    top: 40vh;
    left: 25vw;
    right: 25vw;
    bottom: 0;
    z-index: 100;

    img {
      width: 50vw;
      object-fit: cover;
    }
  }

  .bg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    img {
      width: 100vw;
      object-fit: cover;
    }
  }

  .compass-active {
    top: 65% !important;
  }
}

.compass {
  z-index: 99;
  width: 100%;
  transition: 0.5s;
  left: 50%;
  top: 100%;
  transform: translate(-50%, calc(-50% + 40px));
  width: 80vw;

  &.compass-lock {
    pointer-events: none;
  }

  img {
    width: 100%;
  }

  &.active {
    top: 50% !important;
  }

  &.compass-hide {
    top: 200% !important;
  }

  .compass-img {
    z-index: 10;

    .compass-detect-area {
      width: 40%;
      height: 40%;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .compass-hint {
    right: -10px;
    font-size: 14px;
    color: #fff;
    top: -40px;
    transition: 0.5s 1s;
    pointer-events: none;

    &.hint-hide {
      transition: 0.5s;
      opacity: 0;
    }
  }

  .compass-arrow {
    left: 50%;
    top: -80px;
    background: linear-gradient(to bottom, #fff 50%, rgba(255, 255, 255, 0));
    clip-path: polygon(50% 0, 100% 45%, 85% 45%, 85% 100%, 15% 100%, 15% 45%, 0 45%);
    width: 100px;
    height: 100px;
    margin: 0 0 0 -50px;
    animation: arrowUp 1s infinite;
  }

  .searchingText {
    position: absolute;
    bottom: -15vw;
    color: white;
    font-size: 7vw;
  }
}

.hint {
  top: 0;
  position: fixed;
  z-index: 98;
  color: #fff;
  transition: 0.5s 1s;
  font-size: 14px;
  width: 100vw;

  padding: 20px 10px;

  .hint-content {
    width: 70vw;
    margin: 0;
    line-height: 30px;
  }

  svg {
    margin-left: 20px;
  }
}

/*.full-page video {*/
/*width: 100%;*/
/*height: 100%;*/
/*object-fit: contain;*/
/*}*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s 1s;
}

.fade-enter,
.fade-leave-to {
  transition: opacity 0.5s;
  opacity: 0;
}

.empty-div {
  height: 10vh;
}
</style>

import { render, staticRenderFns } from "./winner.vue?vue&type=template&id=65d57c86&scoped=true&"
import script from "./winner.vue?vue&type=script&lang=js&"
export * from "./winner.vue?vue&type=script&lang=js&"
import style0 from "./winner.vue?vue&type=style&index=0&id=65d57c86&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d57c86",
  null
  
)

export default component.exports
import { useGeolocation } from "vue-use-web";
import { mapActions, mapGetters } from "vuex";
import ModalMixin from "./ModalMixin";

export default {
  mixins: [ModalMixin],
  computed: {
    ...mapGetters("Team", ["team", "isLeader", "team_payload", "character_map"])
  },
  data() {
    return {
      motion: {
        isTrusted: false,
        acceleration: { x: 0, y: 0, z: 0 },
        accelerationIncludingGravity: { x: 0, y: 0, z: 0 },
        rotationRate: { alpha: 0, beta: 0, gamma: 0 },
        interval: 0
      },
      deviceOrientation: {
        absolute: false,
        alpha: 0,
        beta: 0,
        gamma: 0
      },
      roationHorizontal: "",
      roationVertical: "",
      roationFlip: "",
      sendMemberLocationSec: 180,
      sendMemberLocationInterval: null
    };
  },
  setup() {
    const { coords, locatedAt, geolocationError } = useGeolocation({
      enableHighAccuracy: false,
      maximumAge: 3000,
      timeout: 10000
    });

    return {
      coords,
      locatedAt,
      geolocationError
    };
  },
  mounted() {
    if ("ondeviceorientation" in window) {
      window.addEventListener("deviceorientation", event => {
        this.deviceOrientation = {
          ...{},
          ...{
            alpha: event.alpha,
            beta: event.beta,
            gamma: event.gamma,
            absolute: event.absolute
          }
        };
      });
    }
    if (typeof window.DeviceMotionEvent !== "undefined") {
      window.addEventListener("devicemotion", event => {
        this.requestDeviceMotion(event);
      });
    }
  },
  methods: {
    ...mapActions("Team", ["setTeamMemberLocation"]),
    requestDeviceMotion(event) {
      this.motion = {
        ...{},
        ...{
          isTrusted: event.isTrusted,
          acceleration: {
            x: event.acceleration.x,
            y: event.acceleration.y,
            z: event.acceleration.z
          },
          accelerationIncludingGravity: {
            x: event.accelerationIncludingGravity.x,
            y: event.accelerationIncludingGravity.y,
            z: event.accelerationIncludingGravity.z
          },
          rotationRate: {
            alpha: event.rotationRate.alpha,
            beta: event.rotationRate.beta,
            gamma: event.rotationRate.gamma
          },
          interval: event.interval
        }
      };
    },
    sendMemberLocation() {
      this.setTeamMemberLocation({
        longitude: this.coords.longitude,
        latitude: this.coords.latitude
      });
      let secs = 3000;
      if(this.sendMemberLocationInterval) return;
      this.sendMemberLocationInterval = setInterval(() => {
        this.setTeamMemberLocation({
          longitude: this.coords.longitude,
          latitude: this.coords.latitude
        });
      }, secs);
    }
  },
  watch: {
    deviceOrientation: {
      deep: true,
      handler: function(newVal, oldVal) {
        this.roationHorizontal = newVal.alpha < oldVal.alpha ? "right" : "left";
        this.roationVertical = newVal.beta > oldVal.beta ? "up" : "down";
        this.roationFlip = newVal.gamma < oldVal.gamma ? "right" : "left";
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.sendMemberLocationInterval);
    this.sendMemberLocationInterval = null;
  }
};

<template>
  <div class="stage-message">
    <div class="pb-2">
      {{ data.msgLikes }}
    </div>
    <ul class="list-unstyled msg-list">
      <li v-for="(message, index) in data.defaultMsg" :key="index" class="pb-2 d-flex">
        <b>{{ message.name }}</b
        >：
        <div v-if="message.is_hint" @click="copy(message.content)" v-html="message.content"></div>
        <div v-else>{{ message.content }}</div>
      </li>
      <li v-if="data.winnerMsg" class="pb-2">
        <div class="d-flex">
          <b>白爛：</b>
          <div>{{ replaceCharName() }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import { mapGetters } from "vuex";
import { sendMessage } from "../../utils/WebSocket";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import WebSocketMixin from "../../Mixins/WebSocketMixin";
import ModalMixin from "../../Mixins/ModalMixin";

export default {
  name: "fgStageMessage",
  mixins: [Avatar, CharacterFromVuex, TeamFromVuex, WebSocketMixin, ModalMixin],
  props: {
    data: null,
    stage: null,
    step: {
      default: 0
    },
    charName: ""
  },
  computed: {
    ...mapGetters("User", {
      vuex_user: "user",
      character: "character",
      token: "token"
    }),
    messages() {
      if (this?.team_payload?.message) {
        let list = this?.team_payload?.message[this.step - 1] || [];
        list = list.filter(d => {
          if (d.only) return d.only == this.user.id;
          return true;
        });
        let list_length = list.length;
        return list.slice(list_length - this.limit, list_length);
      }
      return [];
    }
  },
  data() {
    return {
      limit: 10,
      talkContent: "",
      clear: false
    };
  },
  methods: {
    copy(content) {
      this.talkContent = content;
    },
    hintMessage: function() {
      let hintIndex = this.data.hintIndex;

      if (this.data.hint.length < hintIndex) {
        alert("沒有提示了");
        return;
      }

      if (typeof this.data.hint[hintIndex] === "object") {
        this.showModal(this.data.hint[hintIndex].content);
        this.$emit("hintAdd");
        return;
      }

      if (hintIndex <= this.data.hint.length) {
        let newMsg = {
          character: this.data.character,
          content: this.data.hint[hintIndex],
          result: ""
        };

        // console.log(this.data.hint[hintIndex])
        let messageData = {
          method: "StageMessage",
          token: this.token,
          stage: this.stage,
          step: this.step,
          only: this.user.id,
          hintIndex: hintIndex,
          newMsg
        };
        sendMessage(messageData);

        messageData = {
          method: "GetHint",
          token: this.token,
          stage: this.stage,
          step: this.step,
          only: this.user.id,
          hintIndex: hintIndex
        };
        sendMessage(messageData);

        this.$emit("hintAdd");
      }
    },

    replaceCharName() {
      let message = this.data.winnerMsg.message[this.data.winnerMsg.winner];
      if (this.charName == "白爛") {
        message = this.data.winnerMsg.message[1];
      }
      if(message){
        return message.replaceAll(/\[charName\]/gi, this.charName);
      }else{
        return message;
      }
    }
  }
};
</script>

<style scoped>
.hint-btn {
  right: 0;
}
</style>
